import React from 'react'
import { imgBanner } from '../assets'
import { Link } from 'react-router-dom'

function Banner() {
	return (
		<div className="banner-container">
			<div className="banner-text">
				<h1> SSV Tunisie en quelques mots ...</h1>
				<p>
					La société Sahel de Viande est leader en Tunisie depuis 40 ans dans la
					transformation et la distribution de viandes rouges .
				</p>
				<p className="margin-top">
					SSV intervient dans les secteurs hôteliers, restauration collective,
					fast-foods , hôpitaux , foyer universitaire , clinique prisons .... La
					société est conforme aux normes sanitaires tunisiennes .
				</p>
				<Link to="/a-propos" className="banner-button">
					Voir plus
				</Link>
			</div>
			<img src={imgBanner} className="banner-img" alt="img banner" />
		</div>
	)
}

export default Banner
