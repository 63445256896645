import {
	agneauEntier,
	bacon,
	boulettedeviande,
	carreAgneau,
	chorizo,
	epauleAgneau,
	foieEtCoeur,
	gigot,
	kebab,
	merguez,
	salami,
	steakhache,
	viandehachee,
	viandeseche,
	viandesechebresaola,
} from '../assets'
const agneauData = [
	{
		id: 1,
		name: 'Agneau',
		img: agneauEntier,
		price: '26.000',
		description: `Le steak haché de FARAH vous fait découvrir l’exquise du goût délicieux en gardant son fondant à cœur quel que soit sa cuisson (Poêle, barbecue..).
        Une recette reproduite fidèlement depuis des générations, respecte le sens de la fibre au hachage et restitue la texture aérée du bœuf haché. Ainsi travaillée, la viande peut être poêlée en quelques minutes et offrir ses bienfaits sans contrainte. Un plaisir qui prend soin de votre famille avec sa richesse naturelle en protéines, vitamines et minéraux.`,
	},
	{
		id: 2,
		name: "Gigot d'agneau",
		img: gigot,
		price: '28.000',
		description: `Viande bovine finement hachée mélangée avec des épices puis embossée dans des boyaux de mouton, des mini merguez d’une longueur de 4 cm.
        Idéales pour des brochettes et des tapas.`,
	},
	{
		id: 3,
		name: "Carré d'agneau",
		img: carreAgneau,
		price: '26.500',
		description: `Les boulettes de viande sont une manière de préparer la viande en boulettes, qui se rencontre partout dans le monde. Elles sont préparées le plus couramment à partir de bœuf ou de porc, mais d'autres viandes sont également utilisées`,
	},
	{
		id: 4,
		name: "Epaule d'agneau",
		img: epauleAgneau,
		price: '27.000',
		description: `description`,
	},
	{
		id: 5,
		name: 'Foie et coeur',
		img: foieEtCoeur,
		price: '29.000',
		description: `description`,
	},
]
export default agneauData
