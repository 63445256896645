import React from 'react'
import { boeufData } from '../data'
import { CharcuterieItem } from '../components'
import { Helmet } from 'react-helmet'

function Boeuf() {
	return (
		<div className="CharcuterieContainer">
			<Helmet title="Boeuf" meta={[{ name: 'Boeuf', content: 'Boeuf' }]} />
			{boeufData.map(item => (
				<>
					<CharcuterieItem item={item} key={item.id} />
				</>
			))}
		</div>
	)
}

export default Boeuf
