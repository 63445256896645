import React, { useState } from 'react'
import {
	Home,
	Footer,
	Charcuterie,
	Produits,
	Boeuf,
	Veau,
	Agneau,
	Apropos,
} from './container'
import { Navbar } from './components'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

function App() {
	const [clicked, setClicked] = useState(1)
	return (
		<Router>
			<Navbar clicked={clicked} setClicked={setClicked} />
			<Switch>
				<Route exact path="/">
					<Home />
				</Route>
				<Route exact path="/charcuterie" component={Charcuterie} />
				<Route exact path="/nos-produits" component={Produits} />
				<Route exact path="/boeuf" component={Boeuf} />
				<Route exact path="/veau" component={Veau} />
				<Route exact path="/agneau" component={Agneau} />
				<Route exact path="/a-propos" component={Apropos} />
			</Switch>
			<Footer />
		</Router>
	)
}

export default App
