import React from 'react'
import '../styles/Navbar.css'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom'

function Navbar({ clicked, setClicked }) {
	console.log(window.location.pathname)
	return (
		<nav>
			{/* logo */}
			<img className="logo-head" src={logo} alt="logo" />

			{/* Navbar */}
			<ul>
				<Link to="/">
					<li
						className={clicked === 1 ? 'active' : ''}
						onClick={() => setClicked(1)}
					>
						ACCEUIL
					</li>
				</Link>
				<Link to="/a-propos">
					<li
						className={clicked === 2 ? 'active' : ''}
						onClick={() => setClicked(2)}
					>
						A PROPOS
					</li>
				</Link>
				<Link to="/nos-produits">
					<li
						className={clicked === 3 ? 'active' : ''}
						onClick={() => setClicked(3)}
					>
						NOS PRODUITS
					</li>
				</Link>
				<li
					className={clicked === 4 ? 'active' : ''}
					onClick={() => setClicked(4)}
				>
					CONTACTEZ NOUS !
				</li>
			</ul>
		</nav>
	)
}

export default Navbar
