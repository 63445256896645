import React from 'react'
import { agneauData } from '../data'
import { CharcuterieItem } from '../components'

function Agneau() {
	return (
		<div className="CharcuterieContainer">
			{agneauData.map(item => (
				<>
					<CharcuterieItem item={item} key={item.id} />
				</>
			))}
		</div>
	)
}

export default Agneau
