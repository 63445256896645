import React from 'react'
import '../styles/Charcuterie.css'
import aa from '../assets/lsen.jpg'

function charcuterieItem({ item: { name, price, id, img } }) {
	console.log(aa)
	return (
		<div className="row">
			<div className="itemContainer">
				<img className="imgItem" src={img} alt={name} />
				<h2>{name}</h2>
			</div>
			<h4 className="hidden">prix : {price} dt</h4>
		</div>
	)
}

export default charcuterieItem
