import {
	aloyaux,
	carcas,
	coeur,
	cuisse,
	epaule,
	foie,
	lsen,
	poitrine,
} from '../assets'

const boeufData = [
	{
		id: 1,
		name: 'Carcasse de boeuf',
		img: carcas,
		price: '12.800',
	},
	{
		id: 2,
		name: 'Cuisse',
		img: cuisse,
		price: '13.800',
	},
	{
		id: 3,
		name: 'Epaule',
		img: epaule,
		price: '13.500',
	},
	{
		id: 4,
		name: 'Aloyaux',
		img: aloyaux,
		price: '13.200',
	},
	{
		id: 5,
		name: 'Poitrine',
		img: poitrine,
		price: '11.800',
	},
	{
		id: 6,
		name: 'Foie',
		img: foie,
		price: '14.800',
	},
	{
		id: 7,
		name: 'Coeur',
		img: coeur,
		price: '14.800',
	},
	{
		id: 8,
		name: 'Langue',
		img: lsen,
		price: '12.000',
	},
]
export default boeufData
