import {
	aloyaux,
	bacon,
	boulettedeviande,
	carcas,
	chorizo,
	coeur,
	cuisse,
	epaule,
	foie,
	kebab,
	lsen,
	merguez,
	poitrine,
	salami,
	steakhache,
	viandehachee,
	viandeseche,
	viandesechebresaola,
} from '../assets'
const veauData = [
	{
		id: 1,
		name: 'Carcasse de veau',
		img: carcas,
		price: '16.800',
	},
	{
		id: 2,
		name: 'Cuisse',
		img: cuisse,
		price: '18.800',
	},
	{
		id: 3,
		name: 'Epaule',
		img: epaule,
		price: '17.800',
	},
	{
		id: 4,
		name: 'Aloyaux',
		img: aloyaux,
		price: '17.500',
	},
	{
		id: 5,
		name: 'Poitrine',
		img: poitrine,
		price: '16.500',
	},
	{
		id: 6,
		name: 'Foie',
		img: foie,
		price: '18.800',
	},
	{
		id: 7,
		name: 'Coeur',
		img: coeur,
		price: '18.800',
	},
	{
		id: 8,
		name: 'Langue',
		img: lsen,
		price: '15.000',
	},
]
export default veauData
