import React from 'react'
import { veauData } from '../data'
import { CharcuterieItem } from '../components'

function Veau() {
	return (
		<div className="CharcuterieContainer">
			{veauData.map(item => (
				<>
					<CharcuterieItem item={item} key={item.id} />
				</>
			))}
		</div>
	)
}

export default Veau
