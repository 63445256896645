import React from 'react'
import logo from '../assets/logo.png'
import '../styles/Footer.css'
import { Route } from 'react-router-dom'
import {
	AiFillPhone,
	AiFillFacebook,
	AiFillInstagram,
	AiFillLinkedin,
	AiFillMail,
	AiFillYoutube,
} from 'react-icons/ai'
import { FiMapPin } from 'react-icons/fi'

function Footer() {
	return (
		<div className="container">
			<div className="col">
				<img src={logo} alt="logo" className="imageFooter" />
				<span>
					<AiFillPhone size="1.2rem" color="green" /> <p>+216 73 338 686</p>
				</span>
				<span>
					<AiFillMail size="1.2rem" color="#EA4335" />
					<p>societe.saheldeviande@gmail.com</p>
				</span>
				<span>
					<FiMapPin size="3rem" color="#EA4335" />
					<p>
						Rte Kalaa Sghira Bouhsina prés de la Sahel de Sport Immeuble Nebli
					</p>
				</span>
			</div>
			<div className="col">
				<h2>A PROPOS</h2>
				<a href="#"> A propos de SSV</a>
				<a href="#"> Certifications</a>
			</div>
			<div className="col">
				<h2>Nos Produits</h2>
				<a href="#"> Grandes surface </a>
				<a href="#"> Restaurants & Fast-Food</a>
				<a href="#"> Hotels & Collectivités</a>
			</div>
			<div className="col">
				<h2>Nous suivre</h2>

				<a
					className="nomargin"
					target="_blank"
					href="https://www.facebook.com/Soci%C3%A9t%C3%A9-Sahel-de-viande-1463970917200603"
				>
					<AiFillFacebook size="1.2rem" color="#3b5998" /> <p>Facebook</p>
				</a>
				<a
					className="nomargin"
					target="_blank"
					href="https://www.instagram.com/ssv_tunisie/"
				>
					<AiFillInstagram size="1.2rem" color="#E1306C" /> <p>Instagram</p>
				</a>
				<a className="nomargin">
					<AiFillYoutube size="1.2rem" color="#FF0000" /> <p>Youtube</p>
				</a>
				<a
					className="nomargin"
					target="_blank"
					href="https://www.linkedin.com/company/societe-sahel-de-viande/"
				>
					<AiFillLinkedin size="1.2rem" color="#0072b1" /> <p>Linkedin</p>
				</a>
			</div>
		</div>
	)
}

export default Footer
