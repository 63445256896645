import React from 'react'
import '../styles/products.css'
import { Link } from 'react-router-dom'

function Products() {
	return (
		<div className="products-container">
			<div className="product-title">
				<h2 className="gras">Gamme de produits</h2>
				<h4 className="italic">Par domaine d'activité</h4>
			</div>
			<div className="images">
				<Link className="product-image one" to="/charcuterie">
					<h3>Charcuterie</h3>
				</Link>
				<Link className="product-image two" to="/boeuf" alt="boeuf">
					<h3>Découpe de boeuf</h3>
				</Link>
				<Link className="product-image three" to="/veau" alt="veau">
					<h3>Découpe de veau</h3>
				</Link>
				<Link className="product-image four" to="/agneau" alt="agneau">
					<h3>Découpe d'agneau</h3>
				</Link>
			</div>
		</div>
	)
}

export default Products
