import React from 'react'
import { Helmet } from 'react-helmet'
import { Products, Banner } from '../components'
import '../styles/home.css'

function Home() {
	return (
		<>
			<Helmet
				title="Acceuil"
				meta={[{ name: 'Acceuil', content: 'Acceuil' }]}
				link={[{ rel: 'icon', type: 'image/png', href: './assets/logo.png' }]}
			/>
			<div className="image-background"></div>
			<Products />
			<Banner />
		</>
	)
}

export default Home
