import React from 'react'
import { imgBanner, apropos, localisation, viande } from '../assets'
import '../styles/Apropos.css'

function Apropos() {
	return (
		<>
			<img src={apropos} alt="logo" className="propo-img" />
			<div className="postBanner">
				La passion du métier, l’exigence de la qualité, le sens du commerce et
				la capacité à s’entourer des meilleurs sont nos principaux atouts !
				<p className="margin-right-auto">Kamel et Youssef Akid</p>
			</div>
			<div className="banner-container bg-white">
				<div className="banner-text color-black">
					<p>
						Depuis 40 ans , la société sahel de viande (SSV-Tunisie) s’est
						imposée le leader en Tunisie dans le domaine de la distribution et
						la transformation des viandes rouges . Ayant fait leur formation
						auprès de leur père M.hdili akid qui été parmi les premiers bouchers
						a sousse dans les années 50 et par la suite sont fils M.kamel akid a
						pris l’initiative et grâce a l’expérience acquise par son père a
						réussit de lancer ca propre société de viande en 1981 qui été nommée
						société sahel de viande (SSV-Tunisie) et la développée jusqu'à
						devenir parmi les premières société des viande en Tunisie .
					</p>
				</div>
				<img
					src={viande}
					className="banner-img height-propos"
					alt="img banner"
				/>
			</div>
			<div className="banner-container bg-white">
				<img src={imgBanner} className="banner-img" alt="img banner" />
				<div className="banner-text color-black">
					<p>
						Son fils M.Youssef akid fraichement diplôme de l’université
						internationale pluridisciplinaire et spécialisé a l'administration
						des affaires a lancés en 2005 dans l’entreprenariat privé en
						reprenant une ancienne société familiale nommée Societe Sahel de
						Viande . Jeunes et motivés, il avait à cœur de perpétuer le
						savoir-faire acquis de quatre générations dans le domaine de
						distribution et transformation des viandes rouges. Le jeune
						dirigeant s’est attelé à faire évoluer et moderniser les outils de
						production et la manière du travail .
					</p>
				</div>
			</div>
			<img src={localisation} alt="localisation" className="localisation" />
		</>
	)
}

export default Apropos
